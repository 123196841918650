<template>
  <CommonCard>
    <div style="padding: 2%;">
      <v-container>
        <v-row class="mb-10" dense>
          <v-col cols="9">
            <span class="text-title-tables">Leads</span>
          </v-col>
          <v-col cols="2" style="align-items: end;">
            <div class="d-flex align-items-center justify-space-between">
              <span class="text-elements-table">Elementos por página</span>
              <select name="choice" class="number" v-model="size">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </v-col>
          <v-col cols="1" style="text-align: end;">
            <span
              class="text-elements-table"
              @click="$router.push('/leads/buscar')"
              style="cursor: pointer;"
            >
              Filtrar
              <v-icon class="mr-1">mdi-filter-variant</v-icon>
            </span>
          </v-col>

          <v-spacer />
        </v-row>
        <v-row dense align="center">
          <v-col cols="10">
            <v-text-field
              class="share-bar-clase"
              label="Buscar"
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              :light="true"
              v-model="filter"
              hint="Puedes buscar por: Nombre(s), Apellido(s), Correo y Campaña"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" style="text-align: end; margin-bottom: 25px;">
            <span class="button-span-style" @click="$router.push('/crear-lead')"
              >Crear nuevo lead
              <v-icon color="#039ECC" class="mr-1"
                >mdi-plus-circle-outline</v-icon
              ></span
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pt-5">
        <v-row>
          <!-- <v-col
            cols="12"
            md="6"
            v-if="rol != 'AGENTCC' && rol != 'AGENTCCSPONSORS'"
          >
            <LeadsMarcacion :bus="bus" />
          </v-col> -->
          <v-col cols="12" md="12">
            <LeadsAgenda :size="size" :filter="filter" />
          </v-col>
        </v-row>
      </v-container>
      <!-- <CommonActionModal
        v-if="showCreacion"
        :dialog="showCreacion"
        @closeModal="showCreacion = false"
        @userChoice="userChoice"
        title="Crear nuevo lead"
        subtitle="¿Ya tiene una póliza emitida desde el portal de la aseguradora?"
        bottonText="Si"
        bottonTextCancel="No"
      /> -->
      <!-- <CrearLead
        v-if="showCreacion"
        :show="showCreacion"
        @close="showCreacion = false"
        @leadCreado="leadCreado"
      /> -->
    </div>
  </CommonCard>
</template>

<script>
import logoSegurify from "@/assets/logo_segurify.png";
//import LeadsMarcacion from "@/components/leads/LeadsMarcacion";
import LeadsAgenda from "@/components/leads/LeadsAgenda";
import { loadScript } from "vue-plugin-load-script";
import { mainAxios } from "../../../mainAxios";
import moment from "moment";
import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-inter-horizontal.png";
import Vue from "vue";
import CommonCard from "../../../components/commonComponents/CommonCard.vue";

export default {
  components: {
    // LeadsMarcacion,
    LeadsAgenda,
    CommonCard,
    // CommonActionModal: () =>
    //   import("@/components/commonComponents/CommonActionModal.vue"),
    // CrearLead: () => import("./CrearLead.vue"),
  },

  data() {
    return {
      logoSegurify,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      showCreacion: false,
      bus: new Vue(),
      intervalo: null,
      data: [],
      isChecking: false,
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,
      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,
      itemsForPage: 10,
      filter: "",
      size:10
    };
  },
  mounted() {
    loadScript(
      "https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js"
    )
      .then(() => {
        window.bpspat.api.init("https://aligev55.zw-callitonce.alestra.net.mx");
      })
      .catch(() => {
        console.error(
          "Fallo al carga el sdk, Refresque la pagina para realizar la llamada"
        );
        location.reload();
      });
    setTimeout(() => {
      this.checkStatus();
      this.intervalo = window.setInterval(() => {
        this.checkStatus();
      }, 10000);
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.intervalo);
  },

  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },

    puedeCrear() {
      const rolesValidos = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "MANAGER",
        "ADMINAGENT",
        "DIRECTO",
        "OPERACIONES",
        "OPERADOR",
        "AGENTCC",
      ];
      return !!rolesValidos.find((e) => e == this.rol);
    },
  },

  methods: {
    leadCreado() {
      this.showCreacion = false;
      this.bus.$emit("reload");
    },
    checkStatus() {
      if (
        (this.rol == "AGENTCC" || this.rol == "AGENTCCSPONSORS") &&
        !this.isChecking
      ) {
        window.bpspat.api.getState(this.statusChecked);
      }
    },
    getLeadsMarcacion() {
      this.data = [];
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/origenLead/llamadaMasiva", config).then((response) => {
        response.data.map((element) => {
          let jsonFormat = element.request_json
            ? JSON.parse(element.request_json)
            : {};
          this.data.push({
            fecha_lead:
              element.fecha_lead ?? ""
                ? moment.utc(element.fecha_lead).format("DD/MM/YYYY")
                : "No Disponible",
            ramo: element.ramo ? element.ramo : "",
            producto: element.producto ? element.producto : "",
            status: element.status ? element.status : "",
            lead:
              jsonFormat.nombre +
              " " +
              jsonFormat.apellidopaterno +
              " " +
              jsonFormat.apellidomaterno,
            uuid_lead_segurify: element.uuid_lead_segurify
              ? element.uuid_lead_segurify
              : "",
            uuid: element.uuid ? element.uuid : "",
            telefono: element.telefono ? element.telefono : "",
            servicio_embebido: element.servicio_embebido
              ? element.servicio_embebido
              : null,
            id: element.id ? element.id : null,
            embedded: true,
            marcacion_automatica: true,
            error: element.error ? element.error : null,
          });
        });
        this.completarllamadaAuto();
      });
    },
    completarllamadaAuto() {
      if (this.data.length > 0 && this.checkIfisLeadView()) {
        var currentItem = {};
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].operador_id == null) {
            currentItem = this.data[i];
            break;
          }
        }
        if (window.location.pathname == "/leads") {
          if (currentItem.id != null && currentItem.error == null) {
            this.$toast("Consultando disponibilidad del lead", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            const duracionAleatoria = Math.floor(Math.random() * 4000) + 1000;
            setTimeout(() => {
              this.checarDisponibilidadLead(currentItem);
            }, duracionAleatoria);
          }else{
            this.$toast.info("No tienes leads disponibles para llamada automática", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        }else{
          this.$toast.info("Abandonaste la vista de búsqueda de lead la marcación automática se detuvo.", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          clearInterval(this.intervalo)
        }
      }
    },
    statusChecked(state) {
      if (state.data.status == "ready") {
        this.getLeadsMarcacion();
      }
    },
    changeRoute(item) {
      this.$router.push({
        path: "/segurify/leads-segurify/lead/" + item.uuid_lead_segurify,
        query: {
          llamadaMasiva: true,
          origenLeadUuid: item.uuid,
        },
      });
    },
    actualizarLead(item) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const enviar = {
        operador_id: "01",
      };
      mainAxios
        .post("/v1/origen/actualizar/" + item.uuid, enviar, config)
        .then((response) => {
          console.log("Se actualizo el lead ", response);
        });
    },
    checarDisponibilidadLead(item) {
      this.isChecking = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get(`/v1/origen/uuid/${item.uuid}`, config).then((response) => {
        if (response.data.operador_id != null) {
          this.$toast.warning("Lead en marcacion", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.isChecking = false;
        } else {
          this.actualizarLead(item);
          this.changeRoute(item);
        }
      });
    },
    checkIfisLeadView() {
      return window.location.pathname == "/leads";
    },
  },
};
</script>
