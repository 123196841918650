<template>
  <div>
    <v-card class="header-table-style">
      <v-toolbar dense flat dark color="#093E4C">
        <v-toolbar-title>
          <span>Agendas del día</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :page.sync="page"
          :items-per-page="size"
          :headers="headers"
          :items="currentItems"
          no-data-text="Sin agendas para mostrar"
          disable-sort
          hide-default-footer
        >
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >
            <div v-if="itemsLength">
              Mostrando {{ pageStart }} - {{ pageStop }} de
              {{ itemsLength }} leads
            </div>
          </template>
          <template v-slot:item.view="{ item }">
            <v-btn icon small>
              <v-icon small @click="changeRoute(item)">
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions style="justify-content: center;">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <v-pagination
            v-model="page"
            :length="pages"
            :total-visible="5"
            circle
            color="#0070C0"
          ></v-pagination>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mainAxios } from "../../mainAxios";
import moment from "moment";

export default {
  data() {
    return {
      headers: [
        {
          text: "Nombre completo",
          align: "start",
          value: "nombre",
        },
        {
          text: "Campaña",
          align: "start",
          value: "producto",
        },
        {
          text: "Correo",
          align: "start",
          value: "correo",
        },
        {
          text: "Fecha",
          align: "start",
          value: "proxima_llamada",
        },
        {
          text: "Hora",
          align: "start",
          value: "proxima_hora",
        },
        {
          text: "",
          align: "start",
          value: "view",
        },
      ],
      data: [],
      page: 1,
    };
  },
  mounted() {
    this.getAgenda();
  },
  methods: {
    getAgenda() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/lead-segurify/agenda", config).then((response) => {
        if (!response.data[0].uuid) return;
        response.data
          .sort((a, b) => {
            const milisA = new Date(a.proxima_llamada).getTime();
            const milisB = new Date(b.proxima_llamada).getTime();
            if (milisA < milisB) return -1;
            if (milisB < milisA) return 1;
            return 0;
          })
          .map((element) => {
            let jsonFormat = JSON.parse(element.request_json);
            this.data.push({
              id: element.id,
              ramo: element.ramo,
              producto: element.producto,
              status: element.status,
              nombre:
                (jsonFormat.nombre ?? '') +
                " " +
                (jsonFormat.apellidopaterno ?? '') +
                " " +
                (jsonFormat.apellidomaterno ?? ''),
              correo: jsonFormat.correo ?? 'No Disponible',
              uuid_lead_segurify: element.uuid_lead_segurify,
              uuid: element.uuid,
              telefono: element.telefono,
              servicio_embebido: element.servicio_embebido,
              proxima_llamada: element.proxima_llamada
                ? moment.utc(element.proxima_llamada).format("DD/MM/YYYY")
                : "No Disponible",
              proxima_hora: element.proxima_llamada
                ? moment.utc(element.proxima_llamada).format("HH:mm:ss")
                : "No Disponible",
              embedded: true,
            });
          });
      });
    },
    changeRoute(item) {
      this.$router.push(
        "/segurify/leads-segurify/lead/" + item.uuid_lead_segurify
      );
    },
  },
  computed:{
    pages() {
      const totalPages = Math.ceil(this.currentItems.length / this.size);
      return totalPages < 1 ? 1 : totalPages;
    },
    currentItems(){
      if(this.filter != '')
        return this.data.filter((lead) => {
          return lead.nombre.toLowerCase().includes(((this.filter).toLowerCase()).trim()) 
          || lead.producto.toLowerCase().includes(((this.filter).toLowerCase()).trim()) 
          || lead.correo.toLowerCase().includes(((this.filter).toLowerCase()).trim()) 
        })
      else return this.data
    }
  },
  props:{
    size:{
      type:Number,
      default:10,
    },
    filter:{
      type:String,
      default:'',
    }
  }
};
</script>

<style>

.header-table-style{
  border-top-left-radius: 20px !important; 
  border-top-right-radius: 20px  !important;
}

</style>